import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import NavbarHome from "../Navbar/NavbarHome";
import "react-datepicker/dist/react-datepicker.css";
import "../../scss/bill.scss";
import "../../scss/FoodBeverage.scss";
import "../../scss/filter.scss";
import axios from "axios";
import BillShowTable from "./Table/BillShowTable";
import ReactSidebar from "../ReactSidebar";
import Error from "../Error";
import DatePicker from "react-datepicker";
import SelectSearchInput from "../SelectSearch";
import Footer from "../Footer";
import Chart from "react-apexcharts";

const Bill = () => {
  let url = process.env.REACT_APP_BASE_URL;
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [selectedOutlet, setSelectedOutlet] = useState("");
  const [token, setToken] = useState("");
  const [outletwisesales, setOutletWiseSales] = useState([]);
  const [order, setOrder] = useState([]);
  const [isOrder, setIsOrder] = useState(true);
  const [food, setFood] = useState([]);
  const [beverage, setBeverage] = useState([]);
  const [beverageGroup, setBeverageGroup] = useState([]);
  const [foodGroup, setFoodGroup] = useState([]);
  const [FoodBeverageSum, setFoodBeverageSum] = useState([]);
  const [totalInfo, setTotalInfo] = useState({});
  const [error, setError] = useState(false);
  const [show, setShow] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState({});
  const [dineinTabs, setDineinTabs] = useState({});
  const [splitDetails, setSplitDetails] = useState({});
  const [arrow, setArrow] = useState(false);
  const [billno, setBillno] = useState("");
  const [outlet, setOutlet] = useState([]);
  const [sales, setSales] = useState([]);
  const [bevsales, setBevSales] = useState([]);
  const [foodsales, setFoodSales] = useState([]);

  useEffect(() => {
    if (show) {
      const newoutlet = [];
      const newsales = [];
      const newbevsales = [];
      const newfoodsales = [];

      dineinTabs.outletwise_sales.forEach((item) => {
        newoutlet.push(item.Outlet);
        newsales.push(item.TotalSales);
        newbevsales.push(item.beverageSale);
        newfoodsales.push(item.foodSale);
      });

      setOutlet(newoutlet);
      setSales(newsales);
      setFoodSales(newfoodsales);
      setBevSales(newbevsales);
    }
  }, [show, dineinTabs.outletwise_sales]);
  const toggleArrow = () => {
    setArrow(!arrow);
  };
  // setOutlet((prevItems) => [...prevItems, ...newoutlet]);
  // setSales((prevItems) => [...prevItems, ...newSales]);

  let start = startDate.toISOString().slice(0, 10);
  let end = endDate.toISOString().slice(0, 10);
  let navigate = useNavigate();

  useEffect(() => {
    let tokenCheck = localStorage.getItem("token");

    if (tokenCheck === null) {
      navigate("/");
    } else {
      setToken(localStorage.getItem("token"));
    }
    setSelectedOutlet(localStorage.getItem("outlet"));
  }, []);

  useEffect(() => {
    if (selectedOutlet) {
      axios
        .post(`${url}/saleshistory`, {
          outlet: `${selectedOutlet}`,
          dateStart: start,
          dateEnd: end,
          token: token,
        })
        .then((response) => {
          console.log(response);
          setShow(true);
          setError(false);
          setTotalInfo(response.data);
          setOrder(response.data.orderDetails);
          setFood(response.data.itemDetails.food);
          setBeverage(response.data.itemDetails.beverage);
          setFoodBeverageSum(response.data.itemDetails.itemSum);
          setBeverageGroup(response.data.itemDetails.beverageGroup);
          setFoodGroup(response.data.itemDetails.foodGroup);
        })
        .catch((error) => {
          setError(true);
          console.log(error);
        });

      axios
        .post(`${url}/summaryreport`, {
          outlet: `${selectedOutlet}`,
          dateStart: start,
          dateEnd: end,
          token: token,
        })
        .then((response) => {
          // console.log(response);
          setSplitDetails(response.data.splitDetails);
          setDineinTabs(response.data);
          setPaymentStatus(response.data.paymentStats);
        })
        .catch((error) => {
          // console.log(error)
        });
    }
  }, [selectedOutlet]);

  const viewBill = () => {
    if (selectedOutlet) {
      axios
        .post(`${url}/saleshistory`, {
          outlet: `${selectedOutlet}`,
          dateStart: start,
          dateEnd: end,
          token: token,
        })
        .then((response) => {
          setIsOrder(true);
          setError(false);
          setTotalInfo(response.data);
          setOrder(response.data.orderDetails);
          setFood(response.data.itemDetails.food);
          setBeverage(response.data.itemDetails.beverage);
          setFoodBeverageSum(response.data.itemDetails.itemSum);
          setBeverageGroup(response.data.itemDetails.beverageGroup);
          setFoodGroup(response.data.itemDetails.foodGroup);
          setShow(true);
        })
        .catch((error) => {
          // console.log(error)
          setError(true);
        });

      axios
        .post(`${url}/summaryreport`, {
          outlet: `${selectedOutlet}`,
          dateStart: start,
          dateEnd: end,
          token: token,
        })
        .then((response) => {
          setSplitDetails(response.data.splitDetails);
          setDineinTabs(response.data);
          setPaymentStatus(response.data.paymentStats);
          setOutletWiseSales(response.data.outletwise_sales);
        })
        .catch((error) => {
          // console.log(error)
        });
    }
  };

  const [billNumber, setBillNumber] = useState([]);
  const [startBillNum, setStartBillNumber] = useState("");
  const [endBillNum, setEndBillNumber] = useState("");
  const billNum = [];

  useEffect(() => {
    order.forEach((item) => {
      {
        billNum.push(item.bill_no);
        setBillNumber(billNum);
        let len = billNum.length;
        setStartBillNumber(billNum[0]);
        setEndBillNumber(billNum[len - 1]);
      }
    });
  }, [order]);

  const handleBillNumber = (e) => {
    setBillno(e.target.value);
    setIsOrder(false);
  };

  useEffect(() => {
    if (billno === "") {
      axios
        .post(`${url}/saleshistory`, {
          outlet: `${selectedOutlet}`,
          dateStart: start,
          dateEnd: end,
          token: token,
        })
        .then((response) => {
          setIsOrder(true);
          setShow(true);
          setError(false);
          setTotalInfo(response.data);
          setOrder(response.data.orderDetails);
          setFood(response.data.itemDetails.food);
          setBeverage(response.data.itemDetails.beverage);
          setFoodBeverageSum(response.data.itemDetails.itemSum);
          setBeverageGroup(response.data.itemDetails.beverageGroup);
          setFoodGroup(response.data.itemDetails.foodGroup);
        })
        .catch((error) => {
          setError(true);
        });
    }
    if (billno) {
      axios
        .post(`${url}/billsearch`, {
          outlet: `${selectedOutlet}`,
          billno: `${billno}`,
          token: token,
        })
        .then((response) => {
          setIsOrder(false);
          setOrder(response.data);
        })
        .catch((error) => {
          // console.log(error)
        });
    }
  }, [billno]);

  return (
    <div>
      <NavbarHome arrow={arrow} />
      <div className="sidebar-container">
        <ReactSidebar
          dineinTabs={dineinTabs}
          paymentStatus={paymentStatus}
          splitDetails={splitDetails}
          toggleArrow={toggleArrow}
          arrow={arrow}
          startBillNum={startBillNum}
          endBillNum={endBillNum}
          FoodBeverageSum={FoodBeverageSum}
        />
        <div className={"container main-content"}>
          <div className="select-options ">
            <div className="date-picker-outlet">
              <div>
                <label className="">Start Date:</label>
                <DatePicker
                  selected={startDate}
                  dateFromat="YYYY-MM-DD"
                  onChange={(date) => setStartDate(date)}
                  className="date-picker"
                />
              </div>
              <div className="enter-bil-no">
                <div>
                  <h6>{selectedOutlet}</h6>
                  <SelectSearchInput
                    token={token}
                    setToken={setToken}
                    setSelectedOutlet={setSelectedOutlet}
                    selectedOutlet={selectedOutlet}
                  />
                </div>
                <div>
                  <input
                    type="text"
                    placeholder="Search by bill no."
                    className="bill-number"
                    onChange={handleBillNumber}
                  />
                </div>
              </div>
              <div>
                <label className="">End Date:</label>
                <DatePicker
                  selected={endDate}
                  dateFromat="yyyy-mm-dd"
                  onChange={(date) => setEndDate(date)}
                  className="date-picker"
                />
              </div>
              <div className="btn-search-view">
                <button onClick={viewBill} className="btn-search">
                  View
                </button>
              </div>
            </div>
            {/* <FilterLg startDate={startDate} token={token} setToken={setToken} setSelectedOutlet={setSelectedOutlet} selectedOutlet={selectedOutlet} setStartDate={setStartDate} setEndDate={setEndDate} viewBill={viewBill} handleBillNumber={handleBillNumber}/> */}
            <div className="date-picker-outlet-sm">
              <div>
                <h6>{selectedOutlet}</h6>
                <SelectSearchInput
                  token={token}
                  setToken={setToken}
                  setSelectedOutlet={setSelectedOutlet}
                  selectedOutlet={selectedOutlet}
                />
              </div>

              <div className="start-date">
                <label className="">Start Date:</label>
                <DatePicker
                  selected={startDate}
                  dateFromat="YYYY-MM-DD"
                  onChange={(date) => setStartDate(date)}
                  className="date-picker"
                />
              </div>
              <div className="end-date">
                <label className="">End Date:</label>
                <DatePicker
                  selected={endDate}
                  dateFromat="yyyy-mm-dd"
                  onChange={(date) => setEndDate(date)}
                  className="date-picker"
                />
              </div>
              <div>
                <label>Bill No:</label>
                <div>
                  <input
                    type="number"
                    placeholder="Search by bill no."
                    className="bill-number"
                    onChange={handleBillNumber}
                  />
                </div>
              </div>
              <div className="btn-search-view">
                <button onClick={viewBill} className="btn-search">
                  View
                </button>
              </div>
            </div>
          </div>
          {/* {show && (
            <DataTable
              selected={selectedOutlet}
              token={token}
              start={start}
              end={end}
            />
          )} */}
          {/* {show && (
            <div className="min-w-full table-auto border-collapse mt-2">
              <table class="table-bill-info">
                <tr>
                  <th className="border px-4 py-2 text-left">Outlet</th>
                  <th className="border px-4 py-2 text-left">Total Sales</th>
                </tr>
                {dineinTabs.outletwise_sales.map((item, index) => (
                  <tr key={index}>
                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                      {item.Outlet}
                    </td>
                    <td style={{ padding: "10px", border: "1px solid #ddd" }}>
                      {item.TotalSales}
                    </td>
                  </tr>
                ))}
              </table>
            </div>
          )} */}

          {show && (
            <div className="container w-3/4">
              <Chart
                className="table-responsive-bill"
                type="bar"
                width={window.innerWidth * 0.7}
                height={500}
                series={[
                  {
                    name: "Total Sales",
                    data: sales,
                    type: "bar",
                  },
                  {
                    name: "Beverage Sales",
                    data: bevsales,
                    type: "line",
                    stroke: { width: 1 },
                  },
                  {
                    name: "Food Sales",
                    data: foodsales,
                    type: "line",
                    stroke: { width: 2 },
                  },
                ]}
                options={{
                  plotOptions: {
                    bar: {
                      horizontal: false,
                      columnWidth: "50%",
                    },
                  },
                  title: {
                    text: "Total Sales by Outlet",
                    style: { fontSize: 20, color: "#000" },
                  },
                  colors: ["#059142", "#FF5733", "#FFC300"],
                  theme: { mode: "light" },
                  barWidth: "10%",

                  xaxis: {
                    tickPlacement: "on",
                    categories: outlet,
                    title: {
                      text: "Outlet",
                      style: { color: "#000000", fontSize: 20 },
                    },
                  },

                  yaxis: [
                    {
                      labels: {
                        formatter: (sales) => `Nrs.${sales}`,
                        style: { fontSize: "15px", colors: ["#059142"] },
                      },
                      title: {
                        text: "Total Sales",
                        style: { color: "#000000", fontSize: 15 },
                      },
                    },
                    {
                      opposite: true,
                      labels: {
                        formatter: (sales) => `Nrs.${sales}`,
                        style: {
                          fontSize: "15px",
                          colors: ["#FF5733", "#FFC300"],
                        },
                      },
                      title: {
                        text: "Sales (Bev + Food)",
                        style: { color: "#000000", fontSize: 15 },
                      },
                    },
                  ],

                  legend: {
                    show: true,
                    position: "right",
                  },

                  dataLabels: {
                    formatter: (sales) => `Nrs. ${sales}`,
                    style: {
                      colors: ["#171717"],
                      fontSize: 12,
                    },
                  },

                  stroke: {
                    width: [0, 2, 3],
                  },

                  grid: {
                    show: true,
                  },
                }}
              />
            </div>
          )}
          {show && (
            <BillShowTable
              order={order}
              isOrder={isOrder}
              totalInfo={totalInfo}
              selected={selectedOutlet}
              token={token}
              food={food}
              foodGroup={foodGroup}
              beverage={beverage}
              beverageGroup={beverageGroup}
              start={start}
              end={end}
            />
          )}
          {error && <Error />}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Bill;
